<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">


                <!-- <tchtree v-model="searchForm.class_ids" ref="classTree"></tchtree>
                <el-input placeholder="学生姓名" v-model="searchForm.keyword" size="small" class="input-with-select"
                    style="width:200px" clearable>
                </el-input> -->


            </div>
        </el-row>
        <el-row v-if="false" class="searchBar flexBetween" style="flex-wrap: wrap;width: 100%;">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">
                <el-select v-model="searchForm.anpai_type" clearable placeholder="安排方式" size="small"
                    style="width:130px;">
                    <el-option :label="item" v-for="(item, idx) in anpaiObj" :key="idx" :value="idx"></el-option>
                </el-select>

                <el-select v-model="searchForm.is_ok" clearable placeholder="专业是否对口" size="small"
                    style="width:130px;margin-left:10px;">
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="0"></el-option>
                </el-select>

                <el-select v-model="searchForm.insured" clearable placeholder="是否投保" size="small"
                    style="width:130px;margin-left:10px;">
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="0"></el-option>
                </el-select>

                <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                    @click="() => { page.current_page = 1; getList() }">搜索</el-button>
            </div>
        </el-row>
        <div class="dividerBar"></div>
        <div class="cusTabTabContainer">
            <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
                style="width:fit-content;width:100%;">
                <el-table-column type="index" label="序号" width="55px" align="center" />
                <el-table-column label="学生姓名" prop="stu_name" width="100px" align="center" />
                <el-table-column label="所在班级" prop="class_name" show-overflow-tooltip width="120px" align="center" />
                <!-- <el-table-column label="系部" prop="yx_name" show-overflow-tooltip width="150px" align="center" /> -->

                <el-table-column label="实习企业" prop="firm_name" show-overflow-tooltip min-width="200px" align="center">
                    <template slot-scope="scope">
                        <span style="">
                            {{ scope.row.remark &&
                            scope.row.remark.stu_enter_info?scope.row.remark.stu_enter_info.firm_name:'' }}
                        </span>

                    </template>
                </el-table-column>
                <el-table-column label="上岗日期" prop="start_time" show-overflow-tooltip width="140px" align="center">
                    <template slot-scope="scope">{{ scope.row.leave_start ? scope.row.leave_start.split(" ")[0] : ''
                        }}</template>
                </el-table-column>
                <el-table-column label="离岗日期" prop="end_time" show-overflow-tooltip width="120px" align="center">
                    <template slot-scope="scope">{{ scope.row.leave_end ? scope.row.leave_end.split(" ")[0] : ''
                        }}</template>
                </el-table-column>

                <el-table-column label="提交时间" prop="cdate" show-overflow-tooltip width="200px" align="center">
                    <template slot-scope="scope">{{ scope.row.cdate }}</template>
                </el-table-column>
                <el-table-column label="是否上传盖章" prop="sx_status" width="90px" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.leave_url" style="color: #409eff;flex-shrink: 0;">
                            已上传
                        </span>
                        <span v-if="!scope.row.leave_url" style="color: #EF342E;flex-shrink: 0;">
                            未上传
                        </span>
                    </template>
                </el-table-column>

                <el-table-column label="审批状态" prop="check_status" width="100px" align="center">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.check_status == 0" type="primary">待审核</el-tag>
                        <el-tag size="mini" v-if="scope.row.sp_status == 1" type="success">审核通过</el-tag>
                        <el-tag size="mini" v-if="scope.row.sp_status == 2" type="danger">审核拒绝</el-tag>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" width="150">
                    <template slot-scope="scope">
                        <el-tag class="pointer" @click="viewInfo(scope.row)" type="primary" size="mini">查看</el-tag>
                        <template v-if="!isGradeLeader(scope.row.class_id)">
                            <el-tag
                                v-if="scope.row.check_status == -1 || scope.row.check_status == 0 || scope.row.check_status == 1"
                                class="pointer ml10" @click="handleModify(scope.row, 'edit')" type="success"
                                size="mini">修改</el-tag>

                            <el-tag class="pointer" v-if="scope.row.check_status === 0" @click="handleModify(scope.row)"
                                type="success" size="mini" style="margin-left:10px;">审批</el-tag>

                            <el-tag class="pointer" v-if="scope.row.check_status === 1" @click="handleRevoke(scope.row)"
                                type="warning" size="mini" style="margin-left:10px;">撤回</el-tag>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
            :current-page="page.current_page" :page-size="page.per_page" :total="page.count"
            layout="total, sizes,prev, pager, next" :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
        </el-pagination>

        <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" @close="isRevoke = false"
            custom-class="cus_dialog" width="1000px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">实习申请</span>
            </div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                    :label="'实习详情' + (applyList.length - idx) + '(' + item.check_txt + (item.check_txt == '撤回' ? '' : '-') + (item.check_txt == '撤回' ? '' : item.sx_status) + ')'"
                    :name="'tab' + item.id" v-for="(item, idx) in applyList" :key="idx">

                </el-tab-pane>
                <el-tab-pane v-if="formData && formData.sxbg && formData.sxbg.length > 0" label="实习变更" name="second">
                    <el-table v-tableFit :data="formData.sxbg" row-key="id" border stripe>
                        <el-table-column label="变更时间" prop="cdate" width="170" align="center">
                            <template slot-scope="scope">{{ scope.row.cdate }}</template>
                        </el-table-column>
                        <el-table-column label="变更后企业名称" prop="firm_name" min-width="140px"></el-table-column>
                        <el-table-column label="变更后企业性质" prop="info" width="120px">
                            <template slot-scope="scope">{{ qyxz[scope.row.info - 1].param_desc }}</template>
                        </el-table-column>
                        <el-table-column label="变更后企业负责人" prop="firm_content_name" width="140px"></el-table-column>
                        <el-table-column label="变更后负责人电话" prop="firm_content_tel" width="140px"></el-table-column>
                        <el-table-column label="变更审批状态" prop="check_status" width="120px" align="center">
                            <template slot-scope="scope">
                                <el-tag size="mini" v-if="scope.row.check_status == -1">待完善</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 0" style="color:orange">待审核</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 1" style="color:green">审核通过</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 2" style="color:red">审核拒绝</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>

                <div class="dialog_right" v-if="activeName != 'second'">
                    <div v-if="formData.check_reason" class="flexStart"
                        style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart" style="min-height: 40px;height: unset;">审批原因</div>
                            <div class="cell flexCloumn flex_1"
                                style="height: unset;justify-content: center;min-height: 40px;color:orangered">
                                {{ formData.check_reason }}
                            </div>
                        </div>
                    </div>
                    <div v-if="formData.revoke_reason" class="flexStart"
                        style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart" style="min-height: 40px;height: unset;">撤回原因</div>
                            <div class="cell flexCloumn flex_1"
                                style="height: unset;justify-content: center;min-height: 40px;color:orangered">
                                {{ formData.revoke_reason }}
                            </div>
                        </div>
                    </div>
                    <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                        <span></span>
                        <span style="font-weight: 400">学生信息</span>
                    </div>

                    <div class="flexStart"
                        style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:33.3%;">
                            <div class="cell flexStart">姓名</div>
                            <div class="cell flexStart flex_1">{{ formData.stu_name }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:33.3%;">
                            <div class="cell flexStart">班级</div>
                            <div class="cell flexStart flex_1">{{ formData.class_name }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:33.3%;">
                            <div class="cell flexStart">实习去向</div>
                            <div class="cell flexStart flex_1">{{ sxqxObj[formData.sxqx] }}</div>
                        </div>

                    </div>
                    <div v-if="formData.sxqx == 1 || formData.sxqx == 7" class="cellGroupBarTitle flexStart"
                        style="margin-top:10px;">
                        <span></span>
                        <span style="font-weight: 400">企业信息</span>
                    </div>
                    <div v-if="formData.sxqx == 1 || formData.sxqx == 7" class="flexStart"
                        style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart">企业名称</div>
                            <div class="cell flexStart flex_1">{{ formData.firm_name }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">社会统一<br />信用代码</div>
                            <div class="cell flexStart flex_1">{{ formData.firm_num }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">企业性质</div>
                            <div class="cell flexStart flex_1">{{ formData.enter_type || qyxzObj[formData.info] }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">企业部门</div>
                            <div class="cell flexStart flex_1">{{ formData.department }}</div>
                        </div>

                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">实习岗位</div>
                            <div class="cell flexStart flex_1">{{ formData.job }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">企业联系人</div>
                            <div class="cell flexStart flex_1">{{ formData.firm_content_name }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">联系人电话</div>
                            <div class="cell flexStart flex_1">{{ formData.firm_content_tel }}</div>
                        </div>

                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">指导老师</div>
                            <div class="cell flexStart flex_1">{{ formData.mentor_name }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">指导老师电话</div>
                            <div class="cell flexStart flex_1">{{ formData.mentor_tel }}</div>
                        </div>


                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">实习薪资</div>
                            <div class="cell flexStart flex_1">{{ formData.xinzi }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">薪资范围</div>
                            <div class="cell flexStart flex_1">{{ salaryObj[formData.salary] }}</div>
                        </div>

                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart">实际上班地址</div>
                            <div class="cell flexStart flex_1">{{ formData.address }}</div>
                        </div>

                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">安排方式</div>
                            <div class="cell flexStart flex_1">{{ anpaiObj[formData.anpai_type] }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">专业对口情况</div>
                            <div class="cell flexStart flex_1">{{ formData.is_ok == 1 ? '对口' : (formData.is_ok == 0 ?
                                '不对口' :
                                '-') }}
                            </div>
                        </div>

                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">有无实习协议</div>
                            <div class="cell flexStart flex_1">{{ formData.is_contract == 1 ? '有' :
                                (formData.is_contract == 0 ?
                                '没有'
                                : '-') }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">是否购买保险</div>
                            <div class="cell flexStart flex_1">{{ formData.insured == 1 ? '有' : (formData.insured == 0 ?
                                '没有' :
                                '-')
                                }}</div>
                        </div>

                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart">有无公积金</div>
                            <div class="cell flexStart flex_1">{{ formData.is_housing_fund == 1 ? '有' :
                                (formData.is_housing_fund == 0
                                ? '没有' : '-') }}</div>
                        </div>

                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">实习开始时间</div>
                            <div class="cell flexStart flex_1">{{ formData.start_time }}</div>
                        </div>
                        <div class="lineRow flexStretch" style="width:50%;">
                            <div class="cell flexStart">实习结束时间</div>
                            <div class="cell flexStart flex_1">{{ formData.end_time }}</div>
                        </div>
                    </div>
                    <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                        <span></span>
                        <span style="font-weight: 400">资料附件</span>
                    </div>
                    <div class="flexStart"
                        style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                        <div class="lineRow flexStretch" style="width:100%;">
                            <div class="cell flexStart" style="min-height: 40px;height: unset;">协议/资料</div>
                            <div class="cell flexStart flex_1"
                                style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
                                <div class="flexCenter" v-for="(f, idx) in formData.cover"
                                    style="position:relative;height:100px;width:100px;border:1px dashed #c0ccda;border-radius:6px;margin-right:8px;margin-bottom:8px;">
                                    <el-image style="max-height:100%;max-width:100%;" :src="f.fileUrl"
                                        :preview-src-list="formData.cover.map(e => { return e.fileUrl })" img="cover"
                                        :z-index="3000"></el-image>
                                </div>
                                <div v-if="!formData.cover || formData.cover == 0" class="" style="width:100%;">-</div>
                            </div>
                        </div>
                    </div>

                    <div style="height:100px;"></div>
                    <el-row v-if="formData.check_status == 0">
                        <el-col :span="18" style="text-align: right; margin-bottom: 30px;">
                            <el-input v-model.trim="spword" style="width: 100%" size="small"
                                placeholder="审批原因；审核拒绝时请填写拒绝原因"></el-input>
                        </el-col>
                        <el-col :span="5" style="text-align: left;padding-left: 10px;">
                            <el-button size="small" type="warning" @click="submitForm(2)">审批拒绝</el-button>
                            <el-button size="small" type="primary" @click="submitForm(1)">审批通过</el-button>
                        </el-col>
                    </el-row>

                    <el-row v-if="formData.check_status == 1 && isRevoke">
                        <el-col :span="18" style="text-align: right; margin-bottom: 30px;">
                            <el-input v-model.trim="spword" type="textarea" style="width: 100%" size="small"
                                maxlength="200" show-word-limit autosize placeholder="撤回原因，非必填"></el-input>
                        </el-col>
                        <el-col :span="5" style="text-align: left;padding-left: 10px;">
                            <el-button size="small" type="warning" @click="submitForm(3)">撤回</el-button>
                        </el-col>
                    </el-row>
                </div>
                <el-tab-pane v-if="formData && formData.sxbg && formData.sxbg.length > 0" label="实习变更" name="second">
                    <el-table v-tableFit :data="formData.sxbg" row-key="id" border stripe>
                        <el-table-column label="变更时间" prop="cdate" width="170" align="center">
                            <template slot-scope="scope">{{ scope.row.cdate }}</template>
                        </el-table-column>
                        <el-table-column label="变更后企业名称" prop="firm_name" min-width="140px"></el-table-column>
                        <el-table-column label="变更后企业性质" prop="info" width="120px">
                            <template slot-scope="scope">{{ qyxz[scope.row.info - 1].param_desc }}</template>
                        </el-table-column>
                        <el-table-column label="变更后企业负责人" prop="firm_content_name" width="140px"></el-table-column>
                        <el-table-column label="变更后负责人电话" prop="firm_content_tel" width="140px"></el-table-column>
                        <el-table-column label="变更审批状态" prop="check_status" width="120px" align="center">
                            <template slot-scope="scope">
                                <el-tag size="mini" v-if="scope.row.check_status == -1">待完善</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 0" style="color:orange">待审核</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 1" style="color:green">审核通过</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 2" style="color:red">审核拒绝</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 3" style="color:red">撤回</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>

        <el-dialog title="导出实习审批" class="cus_dialog" :visible.sync="dialogExportFormVisible" width="25%"
            custom-class="cus_dialog">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">导出实习审批</span>
            </div>
            <el-form>
                <el-form-item label="导出格式">
                    <el-radio v-model="export_format" label="word">Word</el-radio>
                    <el-radio v-model="export_format" label="pdf">PDF</el-radio>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogExportFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="exportWordOrPdf">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog class="cus_dialog" :visible.sync="dialogChangeVisible" width="80%" custom-class="cus_dialog">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">变更记录</span>
            </div>
            <table class="dttable">
                <tr>
                    <td class="tdheadersml">变更时间</td>
                    <td class="tdheadersml">学生姓名</td>
                    <td class="tdheadersml" style="width:40%">企业名称</td>
                    <td class="tdheadersml">岗位</td>
                    <td class="tdheadersml">部门</td>
                    <td class="tdheadersml">负责人</td>
                    <td class="tdheadersml">联系方式</td>
                    <td class="tdheadersml">审核状态</td>
                </tr>
                <tr v-for="(stu, indx) in changeList" :key="indx">
                    <td>{{ stu.check_date }}</td>
                    <td>{{ stu.stu_name }}</td>
                    <td>{{ stu.firm_name }}</td>
                    <td>{{ stu.job }}</td>
                    <td>{{ stu.department }}</td>
                    <td>{{ stu.firm_content_name }}</td>
                    <td>{{ stu.firm_content_tel }}</td>
                    <td>
                        <el-tag size="mini" v-if="stu.check_status == 1" style="color:green">审核通过</el-tag>
                    </td>
                </tr>
            </table>
        </el-dialog>


        <!-- 教师修改实习申请 -->
        <el-dialog ref="ruleForm" :visible.sync="dialogForm_modify" :close-on-click-modal="false"
            @close="isRevoke = false" custom-class="cus_dialog" width="1000px">
            <div slot="title" class="flexBetween">
                <span style="color: #ffffff;">实习申请</span>
            </div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                    :label="'实习详情' + (applyList.length - idx) + '(' + item.check_txt + '-' + item.sx_status + ')'"
                    :name="'tab' + item.id" v-for="(item, idx) in applyList" :key="idx">

                </el-tab-pane>
                <el-tab-pane v-if="formData && formData.sxbg && formData.sxbg.length > 0" label="实习变更" name="second">
                    <el-table v-tableFit :data="formData.sxbg" row-key="id" border stripe>
                        <el-table-column label="变更时间" prop="cdate" width="170" align="center">
                            <template slot-scope="scope">{{ scope.row.cdate }}</template>
                        </el-table-column>
                        <el-table-column label="变更后企业名称" prop="firm_name" min-width="140px"></el-table-column>
                        <el-table-column label="变更后企业性质" prop="info" width="120px">
                            <template slot-scope="scope">{{ qyxz[scope.row.info - 1].param_desc }}</template>
                        </el-table-column>
                        <el-table-column label="变更后企业负责人" prop="firm_content_name" width="140px"></el-table-column>
                        <el-table-column label="变更后负责人电话" prop="firm_content_tel" width="140px"></el-table-column>
                        <el-table-column label="变更审批状态" prop="check_status" width="120px" align="center">
                            <template slot-scope="scope">
                                <el-tag size="mini" v-if="scope.row.check_status == -1">待完善</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 0" style="color:orange">待审核</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 1" style="color:green">审核通过</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 2" style="color:red">审核拒绝</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>

                <div class="dialog_right" v-if="activeName != 'second'">

                    <div>
                        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                            <span></span>
                            <span style="font-weight: 400">学生信息</span>
                        </div>

                        <div class="flexStart"
                            style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                            <div class="lineRow flexStretch" style="width:33.3%;">
                                <div class="cell flexStart">姓名</div>
                                <div class="cell flexStart flex_1">{{ formData.stu_name }}</div>
                            </div>
                            <div class="lineRow flexStretch" style="width:33.3%;">
                                <div class="cell flexStart">班级</div>
                                <div class="cell flexStart flex_1">{{ formData.class_name }}</div>
                            </div>
                            <div class="lineRow flexStretch" style="width:33.3%;">
                                <div class="cell flexStart">实习去向</div>
                                <div class="cell flexStart flex_1">{{ sxqxObj[formData.sxqx] }}</div>
                            </div>

                        </div>
                        <div v-if="formData.sxqx == 1 || formData.sxqx == 7" class="cellGroupBarTitle flexStart"
                            style="margin-top:10px;">
                            <span></span>
                            <span style="font-weight: 400">企业信息</span>
                        </div>
                        <div v-if="formData.sxqx == 1 || formData.sxqx == 7" class="flexStart"
                            style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                            <div class="lineRow flexStretch" style="width:100%;">
                                <div class="cell flexStart">企业名称</div>
                                <div class="cell flexBetween flex_1">

                                    <el-select v-model="formData.firm_name" size="mini" ref="selectSearch"
                                        style="width: 100%;font-size:16px;" filterable remote placeholder="请输入"
                                        :remote-method="remoteMethod" @change="saveNewCompany"
                                        no-match-text="暂无数据，请输入企业名称点击查询拉取数据" no-data-text="暂无数据，请输入企业名称点击查询拉取数据">
                                        <el-option v-for="(item, index) in houxuanList" :key="index" :value="item.name">
                                            <span style="float: left">{{ item.name }}</span>
                                        </el-option>
                                    </el-select>
                                    <div class="ml10">
                                        <el-button size="mini" type="primary" @click="qccApiData">查询</el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart">社会统一<br />信用代码</div>
                                <div class="cell flexStart flex_1">{{ formData.firm_num }}</div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart">企业性质</div>
                                <div class="cell flexStart flex_1">{{ formData.enter_type || qyxzObj[formData.info] }}
                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">企业部门</div>
                                <div class="cell flexStart flex_1">
                                    <el-input placeholder="企业部门" v-model="formData.department" size="small"
                                        class="input-with-select" style="width:100%;font-size:16px;"></el-input>
                                </div>
                            </div>

                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">实习岗位</div>
                                <div class="cell flexStart flex_1">
                                    <el-input placeholder="实习岗位" v-model="formData.job" size="small"
                                        class="input-with-select" style="width:100%;font-size:16px;"></el-input>
                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">企业联系人</div>
                                <div class="cell flexStart flex_1">
                                    <el-input placeholder="实习岗位" v-model="formData.firm_content_name" size="small"
                                        class="input-with-select" style="width:100%;font-size:16px;"></el-input>

                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">联系人电话</div>
                                <div class="cell flexStart flex_1">
                                    <el-input placeholder="实习岗位" v-model="formData.firm_content_tel" size="small"
                                        class="input-with-select" style="width:100%;font-size:16px;"></el-input>
                                </div>
                            </div>

                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">指导老师</div>
                                <div class="cell flexStart flex_1">
                                    <el-input placeholder="实习岗位" v-model="formData.mentor_name" size="small"
                                        class="input-with-select" style="width:100%;font-size:16px;"></el-input>
                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">指导老师电话</div>
                                <div class="cell flexStart flex_1">
                                    <el-input placeholder="实习岗位" v-model="formData.mentor_tel" size="small"
                                        class="input-with-select" style="width:100%;font-size:16px;"></el-input>
                                </div>
                            </div>


                            <div class="lineRow flexStretch" style="width:100%;">
                                <div class="cell flexStart required">实习薪资</div>
                                <div class="cell flexStart flex_1">
                                    <el-input placeholder="实习岗位" v-model="formData.xinzi" size="small"
                                        class="input-with-select" style="width:100%;font-size:16px;"></el-input>
                                </div>
                            </div>

                            <div class="lineRow flexStretch" style="width:100%;">
                                <div class="cell flexStart required">实际上班地址</div>
                                <div class="cell flexStart flex_1">


                                    <el-autocomplete class="felx_1" v-model="formData.address" @input="$forceUpdate()"
                                        :fetch-suggestions="onChangeLocation" @change="onChangeLocation"
                                        @select="onSelect" placeholder="请输入地址" style="width:100%;font-size:16px;">
                                    </el-autocomplete>

                                    <el-amap v-show="mapShow" vid="amapDemo" :zoom="zoom" :center="center"
                                        class="amap-demo" :events="events">
                                        <el-amap-text v-for="(text, index) in texts" :key="'text-' + index"
                                            :text="text.text" :offset="text.offset"
                                            :position="text.position"></el-amap-text>
                                        <el-amap-marker v-for="(marker, index) in markers" :key="'marker-' + index"
                                            :position="marker.position" :events="marker.events"
                                            :visible="marker.visible" :draggable="marker.draggable"
                                            :vid="index"></el-amap-marker>
                                    </el-amap>
                                </div>
                            </div>

                            <div class="lineRow flexStretch" style="width:100%;">
                                <div class="cell flexStart required">安排方式</div>
                                <div class="cell flexStart flex_1">
                                    <el-radio-group v-model="formData.anpai_type">
                                        <el-radio :label="'0'">{{ anpaiObj['0'] }}</el-radio>
                                        <el-radio :label="'1'">{{ anpaiObj['1'] }}</el-radio>
                                        <el-radio :label="'2'">{{ anpaiObj['2'] }}</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">专业对口情况</div>
                                <div class="cell flexStart flex_1">
                                    <el-radio-group v-model="formData.is_ok">
                                        <el-radio :label="'1'">对口</el-radio>
                                        <el-radio :label="'0'">不对口</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>

                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">有无劳动合同</div>
                                <div class="cell flexStart flex_1">
                                    <el-radio-group v-model="formData.is_contract">
                                        <el-radio :label="'1'">有</el-radio>
                                        <el-radio :label="'0'">没有</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">是否购买保险</div>
                                <div class="cell flexStart flex_1">
                                    <el-radio-group v-model="formData.insured">
                                        <el-radio :label="'1'">有</el-radio>
                                        <el-radio :label="'0'">没有</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>

                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">有无公积金</div>
                                <div class="cell flexStart flex_1">
                                    <el-radio-group v-model="formData.is_housing_fund">
                                        <el-radio :label="'1'">有</el-radio>
                                        <el-radio :label="'0'">没有</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>

                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">实习开始时间</div>
                                <div class="cell flexStart flex_1">
                                    <el-date-picker v-model="formData.start_time" type="date" value-format="yyyy-MM-dd"
                                        placeholder="选择日期" :clearable="false">
                                    </el-date-picker>
                                </div>
                            </div>
                            <div class="lineRow flexStretch" style="width:50%;">
                                <div class="cell flexStart required">实习结束时间</div>
                                <div class="cell flexStart flex_1">
                                    <el-date-picker v-model="formData.end_time" type="date" value-format="yyyy-MM-dd"
                                        placeholder="选择日期" :clearable="false">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
                            <span></span>
                            <span style="font-weight: 400">资料附件</span>
                        </div>
                        <div class="flexStart"
                            style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
                            <div class="lineRow flexStretch" style="width:100%;">
                                <div class="cell flexStart" style="min-height: 40px;height: unset;">协议/资料</div>
                                <div class="cell flexStart flex_1"
                                    style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">


                                    <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                                        v-for="(img, idx) in formData.cover" :key="idx">
                                        <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                                            :preview-src-list="[img.fileUrl || img.src]" fit="contain"></el-image>

                                        <div class="imgremove" @click="removeImg(idx)"><i class="el-icon-remove"></i>
                                        </div>
                                    </div>

                                    <div style="display:inline-block;vertical-align: top;">

                                        <el-upload style="display: inline-block; margin-right: 20px" action=""
                                            :http-request="uploadOss" :on-success="uploadCoverFiles"
                                            :show-file-list="false" name="image" accept="image/png, image/jpeg">
                                            <div class="imgaddbtn">
                                                ＋
                                            </div>
                                        </el-upload>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="height:20px;"></div>
                    <div class="flexEnd"
                        style="text-align: left;padding-left: 10px;flex-wrap:no-wrap;margin-bottom: 10px;">
                        <el-button size="small" type="success" icon="el-icon-check"
                            @click="onSaveModifyTea">保存修改</el-button>
                    </div>
                </div>
                <el-tab-pane v-if="formData && formData.sxbg && formData.sxbg.length > 0" label="实习变更" name="second">
                    <el-table v-tableFit :data="formData.sxbg" row-key="id" border stripe>
                        <el-table-column label="变更时间" prop="cdate" width="170" align="center">
                            <template slot-scope="scope">{{ scope.row.cdate }}</template>
                        </el-table-column>
                        <el-table-column label="变更后企业名称" prop="firm_name" min-width="140px"></el-table-column>
                        <el-table-column label="变更后企业性质" prop="info" width="120px">
                            <template slot-scope="scope">{{ qyxz[scope.row.info - 1].param_desc }}</template>
                        </el-table-column>
                        <el-table-column label="变更后企业负责人" prop="firm_content_name" width="140px"></el-table-column>
                        <el-table-column label="变更后负责人电话" prop="firm_content_tel" width="140px"></el-table-column>
                        <el-table-column label="变更审批状态" prop="check_status" width="120px" align="center">
                            <template slot-scope="scope">
                                <el-tag size="mini" v-if="scope.row.check_status == -1">待完善</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 0" style="color:orange">待审核</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 1" style="color:green">审核通过</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 2" style="color:red">审核拒绝</el-tag>
                                <el-tag size="mini" v-if="scope.row.check_status == 3" style="color:red">撤回</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
        <formdialog v-if="dialogshow" :EditItem1="EditItem" :id="EditItem.id"></formdialog>
    </div>
</template>

<script>
import formdialog from "./formdialog.vue"

import { exportWord } from "../../utils/exportword";
import tchtree from "../com/tchTree.vue";
import { cls_sxsp_export_to_excel } from "../../../public/js/Export2Excel";
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
import citys from "../../utils/arae_value.js";
import { mapGetters } from "vuex";

export default {
    components: { tchtree, formdialog },
    data() {
        return {
            dialogshow: false,

            activeName: "first",
            activeTable: "first",
            isRevoke: false,
            searchForm: {
                ktype: "stu_name",
                keyword: "",
                sxqx: "",
                check_status: "",
                class_ids: "",
                anpai_type: "",
                is_effect: "",
            },
            classArr: [],
            tableData: [],
            dialogForm: false,
            modalTitle: "添加",
            formData: {},
            sxqx: ["实习", "参军", "升学/升本", "出国", "长病假", "实习结束"],
            qyxz: [], qyxzObj: {},
            salary: [], salaryObj: {},
            page: {
                count: 0,
                current_page: 1,
                per_page: 50,
                total_page: 0
            },
            overlap: null,
            spword: "",
            sxqxArr: [], sxqxObj: {},
            applyList: [],
            dialogExportFormVisible: false,
            dialogChangeVisible: false,
            export_format: "word",
            changeList: [],
            anpaiObj: {
                0: '学校安排', 1: '自主选择', 2: '定向培养'
            },
            initData: {},
            companyInfo: {},
            houxuanList: [],
            loading: false,
            dialogForm_modify: false,
            // 选点
            mapShow: false,
            zoom: 16,
            center: [116.397477, 39.908692],
            events: {
                click(e) {
                    let { lng, lat } = e.lnglat;
                    self.clickLng = lng;
                    self.clickLat = lat;

                    // 这里通过高德 SDK 完成。
                    var geocoder = new AMap.Geocoder({
                        radius: 1000,
                        extensions: 'all'
                    });
                    geocoder.getAddress([self.clickLng, self.clickLat], function (status, result) {
                        if (status === 'complete' && result.info === 'OK') {
                            if (result && result.regeocode) {
                                self.$nextTick(() => {
                                    self.center = [self.clickLng, self.clickLat];
                                    self.markers[0].position = [self.clickLng, self.clickLat];
                                    self.texts[0].position = [self.clickLng, self.clickLat];
                                    self.texts[0].text = result.regeocode.formattedAddress;
                                    self.address = result.regeocode.formattedAddress;
                                });
                            }
                        }
                    });
                },
                lng: 0,
                lat: 0
                // },
            },
            markers: [
                {
                    position: [116.397477, 39.908692],
                    events: {},
                    visible: true,
                    draggable: false,
                    template: '<span></span>'
                }
            ],
            texts: [
                {
                    position: [116.397477, 39.908692],
                    text: '天安门',
                    offset: [0, -70]
                }
            ],
            posBoxWidth: 0,//监听盒子宽度
            posBoxId: '',
            locationSource: [],
            latLng: "",
            minDays: 0,//最小实习天数,
            salaryOption: [],
            isSysAdmin: false,
            EditItem:{},
        };
    },
    mounted() {
        this.getComType().then(rr => {
            this.getList();
        });
        this.$http.post("/api/sys_sch_config_list", { code: "shixi_mindays" }).then(res => {
            if (res.data.data && res.data.data.length > 0) {
                this.minDays = parseInt(res.data.data[0].kvalue)
            }
        })
        this.$http.post("/api/get_teacher_info").then(res => {
            if (res.data.sys_roles) {
                for (let item of res.data.sys_roles) {
                    if (item.role_name == '系统管理员' || item.role_code.indexOf("sysadmin") >= 0) {
                        this.isSysAdmin = true
                        break
                    }
                }
            }
        })
    },
    computed: {
        ...mapGetters(['isGradeLeader']),
    },
    methods: {
        viewInfo(row, type) {
            this.isView = type === 'show'
            this.EditItem = row
            this.dialogshow = true;
        },
        handleClick(tab, event) {

            for (let item of this.applyList) {
                if ("tab" + item.id == this.activeName) {
                    this.formData = item;
                }
            }
        },
        getComType() {
            return new Promise(resolve => {
                this.$http
                    .post("/api/sys_param_list", {
                        param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
                        used: 0
                    })
                    .then(res => {
                        let qyxz = [];
                        let salary = [];
                        let sxqxArr = [];
                        let qyxzObj = {};
                        let salaryObj = {};
                        let sxqxObj = {};
                        let salaryOption = [];
                        for (let item of res.data) {
                            if (item.param_type == "ENTER_INFO") {
                                qyxz.push(item);
                                qyxzObj[item.param_value] = item.param_desc
                            }
                            if (item.param_type == "SALARY_RANGE") {
                                salary.push(item);
                                salaryObj[item.param_value] = item.param_desc
                                let aa = JSON.parse(JSON.stringify(item.param_desc))
                                aa = item.param_desc.split('-')
                                if (aa && aa[0]) { aa[0] = parseInt(aa[0]); }
                                if (aa && aa[1]) { aa[1] = parseInt(aa[1]) }
                                salaryOption.push(aa)
                            }
                            if (item.param_type == "SHIXI_STATUS") {
                                sxqxArr.push(item);
                                sxqxObj[item.param_value] = item.param_desc
                            }
                        }
                        this.qyxz = qyxz;
                        this.salary = salary;
                        this.sxqxArr = sxqxArr;
                        this.qyxzObj = qyxzObj;
                        this.salaryObj = salaryObj;
                        this.salaryOption = salaryOption
                        this.sxqxObj = sxqxObj;
                        resolve();
                    });
            });
        },
        handlePageChange(page) {
            this.page.current_page = page;
            this.getList();
        },
        handleSizeChange(val) {
            this.page.current_page = 1;
            this.page.per_page = val;
            this.getList()
        },
        formAdd() {
            this.dialogForm = true;
            this.modalTitle = "添加";
            this.formData = {};
        },
        getList() {
            let data = {};
            data.page = this.page.current_page;
            data.pagesize = this.page.per_page;
           
            this.tableData = [];
            this.$http.post("/api/stu_intership_leave_list", data).then(res => {
                res.data.data.map(a => {
                    if (a.remark) {
                        try {
                            a.remark = JSON.parse(a.remark)
                        } catch (error) {
                            a.remark = { contact: "", contactTel: "" }
                        }
                    }
                })
               
                this.tableData = res.data.data;
                this.page = res.data.page;
            });
        },
        handleDel(row) {
            this.$http
                .post("/api/t_sch_terms_delete", {
                    id: row.id
                })
                .then(res => {
                    this.$message.success("删除成功");
                    this.getList();
                });
        },
        handleModify(row, type) {
            this.initData = JSON.parse(JSON.stringify(row))
            this.spword = ''

            this.$http.post("/api/t_sch_stu_enter_from_list", { stu_id: row.stu_id }).then(res => {
                for (let item of res.data) {
                    item.cover = item.cover ? JSON.parse(item.cover) : "";
                    this.sxqxArr.map(d => {
                        try {
                            if (d.param_value == item.sxqx) {
                                item.sxqxLabel = d.param_desc;
                            }
                        } catch { }
                    });
                    item.info = item.info >= 0 ? item.info : item.enter_type
                    item.info = String(item.info)
                    item.infoLabel = this.qyxzObj[item.info]
                    item.anpai_type = String(item.anpai_type)
                    item.is_ok = String(item.is_ok)
                    item.is_contract = String(item.is_contract)
                    item.insured = String(item.insured)
                    item.is_housing_fund = String(item.is_housing_fund)
                    if (row.id == item.id) {
                        this.formData = item;
                        this.activeName = "tab" + item.id;
                    }

                    if (item.check_status == -1) {
                        item.check_txt = "待完善"
                    } else if (item.check_status == 0) {
                        item.check_txt = "待审核"
                    } else if (item.check_status == 1) {
                        item.check_txt = "审核通过"
                    } else if (item.check_status == 2) {
                        item.check_txt = "审核拒绝"
                    } else if (item.check_status == 3) {
                        item.check_txt = "撤回"
                    }

                }
                this.applyList = res.data



                if (type == 'edit') {
                    this.dialogForm_modify = true;
                    this.houxuanList = []
                } else {
                    this.dialogForm = true;
                }
            });
        },
        handleRevoke(row) {
            this.isRevoke = true
            this.handleModify(row)
        },
        submitForm(st) {
            if (st == 2 && !this.spword.trim()) {
                this.$message.error("请输入拒绝原因");
                return;
            }
            if (st == 3) { // 撤回
                this.$confirm("确定撤回？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    if (this.spword.length > 200) {
                        this.$message.error("撤回原因最多200字")
                        return
                    }
                    this.$http
                        .post("/api/t_sch_stu_enter_revoke", {
                            id: this.formData.id,
                            revoke_reason: this.spword
                        })
                        .then(res => {
                            this.$message({
                                type: "success",
                                message: "撤回成功!"
                            });
                            this.getList();
                            this.dialogForm = false;
                        });
                })
            } else {
                this.$confirm("确定审核?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    if (st == 2) {
                        if (!this.spword) {
                            this.$message.error("请输入拒绝原因");
                            return;
                        }
                    }

                    this.$http
                        .post("/api/t_sch_stu_enter_check", {
                            id: this.formData.id,
                            check_status: st,
                            check_reason: this.spword
                        })
                        .then(res => {
                            this.$message({
                                type: "success",
                                message: "审核成功!"
                            });
                            this.getList();
                            this.dialogForm = false;
                        });
                });
            }
        },
        uploadFiles(e) {
            this.$http
                .post("/api/import_stu_enter_xls", {
                    url: e.src
                })
                .then(res => {
                    this.$message.success("导入成功");
                    if (res.data.errmsg && res.data.errmsg.length > 0) {
                        let html = "";
                        for (let msg of res.data.msg) {
                            html +=
                                "<div style='font-size:12px'>" +
                                msg["姓名"] +
                                ":" +
                                msg.msg +
                                "</div>";
                        }
                        this.$alert(html, "未导入数据", {
                            dangerouslyUseHTMLString: true
                        });
                    }
                    this.getList();
                });
        },

        remoteMethod(query) {
            this.formData.firm_name_temp = query
        },

        qccApiData() {
            this.$http.post(`/api/tyc_search_list`, { enter_name: this.formData.firm_name_temp }).then((res) => {
                this.loading = false
                if (res.data && res.data.length > 0) {
                    let arr = this._lo.remove(res.data, function (n) {
                        return n.creditCode && n.creditCode != '';
                    })
                    this.houxuanList = arr
                    this.$refs.selectSearch.toggleMenu()
                }
            })
        },
        saveNewCompany(name) {
            let company = {}
            if (!this.formData.firm_name_temp) {
                this.$message.error('请先查询企业并选择！')
                return;
            }
            this.houxuanList.map(a => {
                if (a.name == name) {
                    company = a
                }
            })
            this.formData.firm_name = company.name || name
            //天眼查的接口公司类型
            let companyType = { 1: "公司", 2: "香港企业", 3: "社会组织", 4: "律所", 5: "事业单位", 6: "基金会", 7: "不存在法人、注册资本、统一社会信用代码、经营状态; ", 8: "台湾企业", 9: "新机构" }

            company.info = 3//默认是私营企
            if (company.companyType == 5) {
                for (let item of this.qyxz) {
                    if (item.param_desc == "国有企业") {
                        company.info = item.param_value
                    }
                }
            } else {
                if (company.name.indexOf("股份有限公司") > 0) {//股份有限公司
                    for (let item of this.qyxz) {
                        if (item.param_desc == "股份制企业") {
                            company.info = item.param_value
                        }
                    }
                } else if (company.name.indexOf("有限责任公司") > 0 || company.name.indexOf("有限公司") > 0) {//股份有限公司
                    for (let item of this.qyxz) {
                        if (item.param_desc == "个人独资企业" || item.param_desc == '有限责任公司(自然人独资)') {
                            company.info = item.param_value
                        }
                    }
                } else {
                    for (let item of this.qyxz) {
                        if (item.param_desc == "个人独资企业" || item.param_desc == '有限责任公司(自然人独资)') {
                            company.info = item.param_value
                        }
                    }
                }
            }

            this.companyInfo = company
            this.$http.post("/api/enterprise_edith5", {
                firm_name: company.name,
                firm_num: company.creditCode,
                firm_principal: company.legalPersonName,
                info: company.info,
                address: ""
            }).then(res => {
                if (res.data.id) {
                    this.formData.enter_id = res.data.id
                    this.getCompanyInfo(res.data.id)
                }
            })
        },
        getCompanyInfo(id) {
            this.$http.post("/api/enterprise_info", { id: id }).then(res => {
                let i = 0
                for (let item of this.qyxz) {
                    if (item.param_value == res.data.info) {

                        break
                    }
                    i++
                }
                if (i >= this.qyxz.length) {
                    i = 2
                }
                this.companyInfo.firm_name = res.data.firm_name
                this.companyInfo.firm_num = res.data.firm_num
                this.companyInfo.id = id;
                this.companyInfo.info = res.data.info;
                let companyInfo = JSON.parse(JSON.stringify(this.companyInfo))
                delete companyInfo.id
                this.formData = Object.assign({}, this.formData, companyInfo)
            })
        },

        onChangeLocation(value, callback) {
            let locationSource = [];
            if (value) {
                this.formData.address = value;
                $.get('https://restapi.amap.com/v3/assistant/inputtips?output=json&keywords=' + this.formData.address + '&key=0b5d4c06598b47042b9b5257889f7e26', (res) => {
                    if (res.tips) {
                        locationSource = res.tips.map(o => { return { value: o.name } })
                    }
                    this.locationSource = res.tips;
                    callback(locationSource);
                })
            } else {
                callback(locationSource);
            }

        },

        onSelect(obj) {
            this.formData.address = obj.value;
            this.locationSource.forEach(element => {
                if (element.name == this.formData.address) {
                    if (element.location && element.location.length > 0) {
                        this.mapShow = true;
                        const locArr = element.location.split(',').map(Number);
                        this.center = locArr;
                        this.markers[0].position = locArr;
                        this.texts[0].position = locArr;
                    }
                    this.texts[0].text = '<b>' + element.name + '</b></br><span>' + element.address + '</span>';
                    this.formData.address = element.name + '(' + element.address + ')';
                    this.latLng = element.location;

                    if (element.adcode) {
                        let areaCodes = this.getParent(citys, element.adcode)
                        let areaNames = this.getParent(citys, element.adcode)
                        if (areaCodes && areaCodes.length) {
                            params.shi_code = areaCodes[1].value
                            areaCodes = areaCodes.map(a => a.value).reverse().join(',')
                        }
                        if (areaNames && areaNames.length) {
                            areaNames = areaNames.map(a => a.label).reverse().join(',')
                        }
                        this.formData.area_code = areaCodes
                        this.formData.area_name = areaNames
                    }

                    return;
                }
            });
        },
        getParent(list, id) {
            for (let i in list) {
                if (list[i].value === id) {
                    return [list[i]]
                }
                if (list[i].children != null) {
                    let node = this.getParent(list[i].children, id)
                    if (node !== undefined) {
                        return node.concat(list[i])
                    }
                }
            }
        },
        uploadCoverFiles(e) {
            this.formData.cover = this.formData.cover ? this.formData.cover : []
            this.formData.cover.push({
                fileName: e.fileName,
                fileUrl: e.src,
            })
        },
        removeImg(idx) {
            this.formData.cover.splice(idx, 1)
        },
        onSaveModifyTea() {
            let params = { ...this.formData }
            params.addr_lng = 0
            params.addr_lat = 0
            if (this.latLng && this.latLng.indexOf(',') > 0) {
                let arr = this.latLng.split(',')
                params.addr_lng = arr[0]
                params.addr_lat = arr[1]
            }
            let oldImageArr = [];
            let pmidVerify = (this.$store.state.pmid != '215' && this.$store.state.pmid != '225') ? true : false
            if ((params.sxqx == 1 || params.sxqx == 7) && !this.isSysAdmin) {
                if (!params.firm_name || !params.firm_num) {
                    this.$message.error('请选择实习单位！')
                    return
                }

                if (!params.firm_content_name && pmidVerify) {
                    this.$message.error('请输入企业联系人姓名~')
                    return
                }
                var myreg1 = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (!params.firm_content_tel && pmidVerify) {
                    this.$message.error('请输入企业联系人电话~')
                    return
                }
                if (params.firm_content_tel && !myreg1.test(params.firm_content_tel)) {
                    this.$message.error('企业联系人电话请输入正确的手机号');
                    return
                }

                if (!params.mentor_name && pmidVerify) {
                    this.$message.error('请输入指导老师姓名~')
                    return
                }
                if (!params.mentor_tel && pmidVerify) {
                    this.$message.error('请输入指导老师电话~')
                    return
                }
                if (params.mentor_tel && !myreg1.test(params.mentor_tel)) {
                    this.$message.error('指导老师电话请输入正确的手机号');
                    return
                }


                params.enter_type = this.companyInfo.info


                if (!params.department && pmidVerify) {
                    this.$message.error('请填写实习部门~')
                    return
                }
                if (!params.job && pmidVerify) {
                    this.$message.error('请填写实习岗位~')
                    return
                }
                if (!this.formData.xinzi && pmidVerify) {
                    this.$message.error('请填写实习薪资~')
                    return
                }
                if (this.formData.xinzi) {
                    let xinzi = this.formData.xinzi
                    let indexAllowance = -1
                    this.salaryOption.map((a, ia) => {
                        if (a && a.length == 2) {
                            if (a[0] <= xinzi && xinzi <= a[1]) {
                                indexAllowance = ia
                                params.salary = this.salary[indexAllowance].param_value
                                return
                            }
                        } else if (a && a.length == 1 && a[0]) {
                            if (xinzi <= a[0] && a[0] == 2000) {
                                indexAllowance = ia
                                params.salary = this.salary[indexAllowance].param_value
                                return
                            }
                            if (xinzi >= a[0] && a[0] == 5000) {
                                indexAllowance = ia
                                params.salary = this.salary[indexAllowance].param_value
                                return
                            }
                        } else {
                            indexAllowance = 0
                            params.salary = this.salary[indexAllowance].param_value
                        }

                    })
                }

                if (!params.address && pmidVerify) {
                    this.$message.error('请选择实际上班地址~')
                    return
                }
                if (!params.anpai_type && params.anpai_type != 0 && pmidVerify) {
                    this.$message.error('请选择安排方式~')
                    return
                }
                if (!params.is_ok && pmidVerify) {
                    this.$message.error('请选择专业对口情况~')
                    return
                }
                if (!params.is_contract && pmidVerify) {
                    this.$message.error('请选择是否有实习协议~')
                    return
                }
                if (!params.insured && pmidVerify) {
                    this.$message.error('请选择是否购买保险~')
                    return
                }
                if (!params.is_housing_fund && pmidVerify) {
                    this.$message.error('请选是否有公积金~')
                    return
                }

                if (!params.start_time) {
                    this.$message.error('请选择实习开始时间~')
                    return
                }
                if (!params.end_time) {
                    this.$message.error('请选择实习结束时间~')
                    return
                }
                //计算开始时间和结束时间间隔不能小于最小天数
                let stime = new Date(params.start_time.replace(/-/g, '/')).getTime()
                let etime = new Date(params.end_time.replace(/-/g, '/')).getTime()

                if (etime - stime < (this.minDays * 24 * 60 * 60 * 1000)) {
                    this.$message.error('最少实习天数为' + this.minDays + "天")
                    return
                }
                if (!params.insured || (params.insured != 0 && params.insured != 1)) {
                    params.insured = 0
                }
                if (this.formData.cover.length > 0) {
                    for (let img of this.formData.cover) {
                        if (img.fileUrl.indexOf("https") >= 0) {
                            oldImageArr.push(img)
                        }
                    }
                }
                params.cover = JSON.stringify(oldImageArr)
                console.log(1280, params)
                this.$http.post("/api/t_sch_stu_enter_edit2", params).then((res) => {
                    this.$message.success("修改成功!");
                    this.dialogForm_modify = false;
                    this.getList();

                })
            } else {
                if (params.enter_type && params.enter_type.length > 1) {
                    console.log('isSysAdmin:true', params.enter_type)
                    delete params.enter_type
                }
                if (!params.insured || (params.insured != 0 && params.insured != 1)) {
                    params.insured = 0
                }
                if (this.formData.cover.length > 0) {
                    for (let img of this.formData.cover) {
                        if (img.fileUrl.indexOf("https") >= 0) {
                            oldImageArr.push(img)
                        }
                    }
                }
                params.cover = JSON.stringify(oldImageArr)
                console.log(1408, params)
                this.$http.post("/api/t_sch_stu_enter_edit2", params).then((res) => {
                    this.$message.success("修改成功!");
                    this.dialogForm_modify = false;
                    this.getList();

                })
            }

        },
        tongbuJava() {
            this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let loadingInstance = this.$loading({
                    lock: true,
                    text: "已发送请求，请稍等...",
                    spinner: 'el-icon-loading'
                })
                this.$http.post("/api/sync_stu_enter", {}).then(res => {
                    loadingInstance.close()
                    this.$message.success('后台同步中，请刷新查看')
                    this.getList();
                }).catch(err => {
                    this.$message.error('同步失败')
                    loadingInstance.close()
                })

            }).catch(() => {

            });
        },
        exportWordOrPdf() {
            this.dialogExportFormVisible = false
            let classIds = this.searchForm.class_ids
            if (classIds) {
                if (classIds.split(',').length > 1) {
                    this.$message.warning('请选择具体班级')
                    return
                }
            } else {
                this.$message.warning('请选择班级')
                return
            }
            let classInfo = this.getCls(this.$refs.classTree.TreeData, classIds)
            if (this.export_format === 'pdf') {
                this.$http.post(this.$pdfUrl + '/exportTchPdf_sxsp_new', {
                    pmid: window.pmid,
                    latest: 1,
                    stuArr: [
                        {
                            id: classInfo.id,
                            name: classInfo.name,
                            class_id: classIds,
                        }
                    ]
                }).then(res => {
                    this.classArr = []
                    this.$message({
                        type: 'success',
                        message: '生成成功!'
                    });
                    window.open(res.data.url, "_blank")
                })
                return
            } else if (this.export_format == 'xls') {
                this.$http.post('/api/cls_stu_enter_export', {
                    pmid: this.$store.state.pmid,
                    uid: this.$store.state.teacher.id,
                    class_ids: this.searchForm.class_ids
                }).then(res => {
                    let data = res.data
                    // 导出excel
                    let classInfo = data.classes[0]
                    let year = parseInt(classInfo.major_name)
                    if (isNaN(year)) {
                        year = new Date().getFullYear()
                    }
                    let excelData = [
                        [`常州旅游商贸高等职业技术学校${year + 5}届毕业生岗位实习情况审批表`],
                        [
                            "系部", classInfo.xibu_name,
                            "专业", "",
                            "班级", classInfo.class_name,
                            "人数", data.enters.length,
                            "实习\r\n起止时间", classInfo.start_time && classInfo.end_time ? (classInfo.start_time + "\r\n至\r\n" + classInfo.end_time) : "",
                            "班主任", classInfo.teacher_name ?? "",
                            "联系电话", classInfo.phone ?? "",
                            "指导老师", classInfo.mentor_name ?? "",
                            "联系电话", classInfo.mentor_tel ?? ""
                        ],
                        ["序号", "学号", "姓名", "实习类别", "实习单位名称", "", "单位性质", "岗位", "实习津贴\r\n（元/月）", "单位地址", "", "", "单位\r\n联系人", "联系电话", "是否\r\n专业对口", "是否参加实习责任保险", "法定监护人（或家长）是否同意", "备注"],
                    ]
                    for (let i = 0; i < data.enters.length; i++) {
                        let item = data.enters[i]
                        let param = [
                            i + 1,
                            item.stu_code,
                            item.stu_name,
                            item.sx_type,
                            item.firm_name || "",
                            "",
                            item.enter_info || "",
                            item.job || "",
                            item.salary || "",
                            item.address || "",
                            "",
                            "",
                            item.firm_content_name || "",
                            item.firm_content_tel || "",
                            item.is_ok ? '是' : '否',
                            item.insured ? '是' : '否',
                            "",
                            ""
                        ]
                        excelData.push(param)
                    }
                    excelData.push([
                        "系部\r\n意见",
                        "系主任（签章）：\r\n                      年   月   日",
                        "", "", "",
                        "产学合作处\r\n意见",
                        "负责人（签章）：\r\n                     年   月   日",
                        "", "", "",
                        "教务处\r\n意见",
                        "负责人（签章）：\r\n                    年   月   日",
                        "", "",
                        "学校\r\n意见",
                        "审批人（签章）：\r\n                    年   月   日",
                        "", ""
                    ])
                    cls_sxsp_export_to_excel(excelData)
                })
                return
            }
            this.$http.post('/api/t_sch_stu_enter_export', {
                class_ids: this.searchForm.class_ids
            }).then(res => {
                let data = res.data
                let date = new Date()
                data.year = date.getFullYear()
                data.month = date.getMonth() + 1
                data.day = date.getDate()
                data.classes.map(d => {
                    d.teacher_name = d.teacher_name ?? ""
                    d.phone = d.phone ?? ""
                    d.mentor_name = d.mentor_name ?? ""
                    d.mentor_tel = d.mentor_tel ?? ""
                    d.major_name = d.major_name ?? d.yx_name
                    d.start_time = d.start_time.replaceAll('-', '/')
                    d.end_time = d.end_time.replaceAll('-', '/')
                })
                data.enters.map(e => {
                    e.department = e.department ?? ""
                    e.job = e.job ?? ""
                    e.remarks = e.remarks ?? ""
                    e.firm_content_name = e.firm_content_name ?? ""
                    e.firm_content_tel = e.firm_content_tel ?? ""
                    e.salary = e.salary ?? ""
                    e.enter_info = e.enter_info ?? ""
                })
                exportWord('./docs/毕业实习审批表模板.docx', data, '毕业实习审批表.docx')
            })
        },
        showChangeList(row) {
            this.$http.post("/api/t_sch_stu_enter_from_list", { stu_id: row.stu_id, check_status: 1 }).then(res => {

                this.changeList = res.data;
                this.dialogChangeVisible = true
            })
        },
        getCls(data, id) {
            for (let item of data) {
                if (item.id === Number(id)) {
                    return item
                }
                if (item.children && item.children.length) {
                    return this.getCls(item.children, id)
                }
            }
        },
        clsExportExcel() {
            this.export_format = 'xls'
            this.exportWordOrPdf()
        },
        changeStatus(val) {
            if (val != 1) {
                this.searchForm.is_effect = ""
            }
        },
        tableRowClassName({ row, rowIndex }) {
            if (row.sp_chaoshi) {
                return "warning-row";
            } else {
                return "";
            }
        },
    }
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__body {
    padding: 0px 21px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}

/deep/ .el-tabs__item {
    font-size: 18px;
}

.dialog_right {
    .lineRow {
        .cell:first-of-type {
            width: 120px;
            padding-left: 8px;
        }

    }

    .imgaddbtn {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px dotted #ccc;
        font-size: 30px;
        cursor: pointer;
    }

    .imgremove {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;

        cursor: pointer;
        position: absolute;
        top: -5px;
        right: -5px;
        color: #ccc;
    }

    .imgremove:hover {
        color: orange;
    }
}

.cusTabTabContainer {
    flex: 1;
    width: 100%;

    ::v-deep .el-tabs {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    ::v-deep .el-tabs__content {
        flex: 1;
    }

    ::v-deep .el-tab-pane {
        height: 100%;
    }
}
</style>