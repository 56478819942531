import { render, staticRenderFns } from "./leaveIntership.vue?vue&type=template&id=01b0c45f&scoped=true&"
import script from "./leaveIntership.vue?vue&type=script&lang=js&"
export * from "./leaveIntership.vue?vue&type=script&lang=js&"
import style0 from "./leaveIntership.vue?vue&type=style&index=0&id=01b0c45f&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b0c45f",
  null
  
)

export default component.exports